// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import  "../stylesheets/application.css";
import Dropzone from "dropzone";
import flatpickr from "flatpickr";
import SlimSelect from 'slim-select';
import tippy from 'tippy.js';

require("slim-select/dist/slimselect.css");
require("flatpickr/dist/flatpickr.css");
require('tippy.js/dist/tippy.css');
require('react-datetime/css/react-datetime.css');

Dropzone.autoDiscover = false;

Rails.start();
Turbolinks.start();
ActiveStorage.start();

$(document).on('turbolinks:load', function() {
  flatpickr(".flatpickr-date");
  flatpickr(".flatpickr-datetime", {
    enableTime: true,
    dateFormat: "Y-m-d H:i",
  });
  flatpickr(".flatpickr-date-past", {
    maxDate: "today"
  });

  $(window).keydown(function(event){
    if(event.keyCode === 13) {
      event.preventDefault();
      return false;
    }
  });

  if ($(".slim-select").length > 0) {
    new SlimSelect({
      select: '.slim-select',
      placeholder: 'Pick Clinic Locations',
    });
  };

  tippy('.authorization-notifications', {
    content: "Turn on to receive an email when treatment initiations are authorized by Kindbody",
  });

  tippy('.tippy-tooltip');
});

$.fn.uncheckableRadio = function() {
  return this.each(function() {
    $(this).mousedown(function() {
      $(this).data('wasChecked', this.checked);
    });

    $(this).click(function() {
      if ($(this).data('wasChecked'))
        this.checked = false;
    });
  });
};

// Add Choices Dropdown
/*const Choices = require('choices.js');
document.addEventListener("turbolinks:load", function() {
  var dropDownSelects = new Choices('.dropdown-choice-select');
});*/
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
