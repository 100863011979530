import React from "react";
import Datetime from 'react-datetime';

const BirthdatePicker = ({inputProps, initialValue}) => {

  const getInitialDateValue = () => {
    if (initialValue) {
      const date = new Date(initialValue);
      if (date !== 'Invalid Date') {
        return date;
      }
    }
  };

  const getInitialViewDate = () => {
    const initialDateValue = getInitialDateValue();
    if (initialDateValue) {
      return initialDateValue;
    }
    return new Date(1996, 6, 15);
  };

  return <Datetime
    initialViewMode={'years'}
    inputProps={inputProps}
    initialValue={getInitialDateValue()}
    initialViewDate={getInitialViewDate()}
    input={true}
    timeFormat={false}
  />;
};

export default BirthdatePicker;
