import React from "react";

const Modal = ({name, showModal, setShowModal}) => {
  const node = React.useRef();
  const handleOutsideClick = e => {
    if (node.current && node.current.contains(e.target)) {
      return;
    }

    if (showModal) {
      setShowModal(false);
    }

  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div>
      <div
          className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div ref={node}
              className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div
                className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-3xl font-semibold">
                {name}
              </h3>
              <button
                  className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowModal(false)}
              >
    <span
        className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
    </span>
              </button>
            </div>
            <div className="relative p-6 flex-auto">
              <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                To transfer a new patient, please contact the Kindbody Provider
                Relations
                team at <a className="text-blue-700 font-bold mb-2"
                           href="mailto:providerrelations@kindbody.com">providerrelations@kindbody.com</a>
              </p>
            </div>
            <div
                className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
              <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setShowModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  )
}

const TransferPatientModal = ({name}) => {
  const [showModal, setShowModal] = React.useState(false);
  return (
      <div>
        <button
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 max-w-md"
            type="button"
            onClick={() => setShowModal(true)}
        >
          {name}
        </button>
        {showModal ? (
          <Modal name={name} showModal={showModal} setShowModal={setShowModal} />
        ) : null}
      </div>
  );
};

export default TransferPatientModal;
